
// @ is an alias to /sr
import {
  defineComponent,
  inject,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import FilePreview from "@/components/parts/FilePreview.vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";

import {
  formatThounsandNumber,
  getFilePathFromUrl,
} from "@/lib/utility/stringUtil";

import { formatLocalDate } from "@/lib/utility/common";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import { useRoute } from "vue-router";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
export default defineComponent({
  name: "ContractSummary",
  components: {
    FlatButton,
    HeaderTitleBar,
    FilePreview,
    AfterLoginPageLayout,
    PdfViewer,
    AvatarCircle,
  },
  setup() {
    const state = reactive({
      breadScrumPaths: [],
      files: [],
      selectedFileIndex: 0,
      isShowAdvanced: true,
    });
    const contractState = ref({
      contractInformation: {},
      contractCustomFields: [],
    });
    const route = useRoute();
    const { getAccessCode } = useSignFunctions();

    const isMobile = inject("isMobile");

    const { getContractsFullInformation } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    onBeforeMount(async () => {
      const contractId = (route.query.contractId as string) ?? "";
      const accessCode = getAccessCode(contractId);
      const payload = (await getContractsFullInformation(
        contractId,
        accessCode
      )) as any;

      contractState.value = { ...contractState.value, ...payload };
      state.files = payload.contractFiles.map((file: any) => ({
        id: file.id,
        name: file.fileName,
        path: getFilePathFromUrl(file.filePath),
        type: file.fileType,
      }));
    });

    return {
      ...toRefs(state),
      contractState,
      isMobile,
      getFilePathFromUrl,
      formatThounsandNumber,
      formatLocalDate,
    };
  },
  methods: {
    downloadFile(file: any) {
      const link = document.createElement("a");
      link.href = file.path;
      link.target = "_blank";
      link.click();
    },
  },
});
